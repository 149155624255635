import React from "react"

import Layout from "../components/layouts/Layout"
import { SEO } from "../components/utilities"
import { Section } from "../components/elements"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Section>
      <h1>NOT FOUND</h1>
      <p>Sorry the page you are looking for doesn't exist</p>
    </Section>
  </Layout>
)

export default NotFoundPage
